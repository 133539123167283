<template>
  <div>
    <contentProgress
      :content="content"
      :screen="screen"
      :playerInstance="playerInstance"
      @playContent="play"
      @removeFromContinueData="remove"
      :isContinueWatch="isContinueWatch"
    >
    </contentProgress>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
    },
    screen: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
    isContinueWatch: {
      type: Boolean,
    },
  },
  methods: {
    play() {
      this.$emit("playContent", this.content);
    },
    remove() {
      this.$emit("removeFromContinueData", this.content);
    },
  },
  components: {
    contentProgress: () => import(/* webpackchunkname: contentprogress */ "@/components/Templates/contentProgress.vue"),
  },
};
</script>

<style></style>
